import { API_BASE_URL } from "./BaseUrl"
import { postApiWithoutToken, getApiWithToken, putApiWithToken, deleteApi, postApiWithToken, imgUploadApi } from "./ApiInterfaces"
import axios from "axios";


// Adimin Login 
export const loginAdmin = async (values) => {
    const res = await postApiWithoutToken("/admin/login", values)
    return res;
}

export const getAdminDetailsApi = async () => {
    const res = await getApiWithToken("/admin/getAdminDetails")
    return res;
}


// Admin Get Dashboard Data

export const adminGetDashboardData = async () => {
    const res = await getApiWithToken(`/admin/getAllDashboardData?from=&to=`)
    return res;
}

export const adminGenerateReport = async (type, from, to) => {
    const res = await getApiWithToken(`/admin/generateReports?startDate=${from}&endDate=${to}&reportType=${type}`)
    return res;
}


export const adminProfileUpdate = async (values) => {
    const res = await putApiWithToken("/admin/updateAdminProfile", values)
    return res;
}

export const forgetPassword = async (values) => {
    const res = await postApiWithoutToken("/admin/forgetPassword", values)
    return res;
}

export const resetPassword = async (values) => {

    return axios.put(API_BASE_URL + "/admin/resetPassword", { password: values?.password }, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${values?.token}`,
        },
    });

}

export const getAllUsers = async (limit, page, search = "") => {
    const res = await getApiWithToken(`/admin/getAllUser?page=${page}&limit=${limit}&search=${search}`);
    return res;
}

// Admin Get All Users
export const adminGetAllUserList = async (limit = "", page = "", search = "", role = "") => {
    const res = await getApiWithToken(`/admin/getPartnersList?role=${role}&search=${search}&page=${page}&limit=${limit}`)
    return res;
}
export const adminUserChagneStatusApi = async (data) => {
    const res = await putApiWithToken(`/admin/approveOrRejectuser`, data)
    return res;
}
export const adminGetAllDeletedUserList = async (limit, page, search = "", role = "") => {
    const res = await getApiWithToken(`/admin/getAllDeletedUserList?role=${role}&search=${search}&page=${page}&limit=${limit}`)
    return res;
}
export const adminChangePartnerApprovalStatus = async (data) => {
    const res = await postApiWithToken(`/admin/changePartnerApprovalStatus`, data)
    return res;
}
export const adminChangeDriverDocumentsStatusApi = async (data) => {
    const res = await putApiWithToken(`/admin/approveOrRejectDriverDocuments`, data)
    return res;
}

export const approveOrRejectRentalVehicleStatusApi = async (data) => {
    const res = await putApiWithToken(`/admin/approveOrRejectRentalVehicleStatus`, data)
    return res;
}

export const approveOrRejectRestaurantDocApi = async (data) => {
    const res = await putApiWithToken(`/admin/approveOrRejectRestaurantDoc`, data)
    return res;
}

export const adminGetDriverDetailsById = async (id) => {
    const res = await getApiWithToken(`/admin/getDriverDetailsById?id=${id}`)
    return res;
}

export const rentalvendorDetailsById = async (id) => {
    const res = await getApiWithToken(`/admin/rentalvendorDetailsById?id=${id}`)
    return res;
}

export const editRentalVendorApi = async (data) => {
    const res = await putApiWithToken(`/admin/editRentalVendor`,data)
    return res;
}

export const editRestaurantApi = async (data) => {
    const res = await putApiWithToken(`/admin/editRestaurant`,data)
    return res;
}

export const restaurantById = async (id) => {
    const res = await getApiWithToken(`/admin/restaurantById?id=${id}`)
    return res;
}

export const getVehiclesListApi = () => {
    return getApiWithToken(`/admin/getVehiclesList`);
}
export const getVehiclesByIdApi = (id) => {
    return getApiWithToken(`/admin/getVehiclesList?vehicleId=${id}`);
}

export const addVehicleCategoryApi = (payload) => {
    return postApiWithToken(`/admin/addVehicleCategory`, payload)
}

export const getAllUsersListApi = (limit = "", page = "", search = "") => {
    return getApiWithToken(`/admin/getAllUsersList?search=${search}&page=${page}&limit=${limit}`)
}

export const getAdminConfigurationApi = () => {
    return getApiWithToken(`/admin/getAdminConfiguration`);
}

export const updateAdminConfigurationsApi = (payload) => {
    return putApiWithToken(`/admin/updateAdminConfigurations`, payload)
}

export const getCategoryListApi = (bookingType="", countryId="", cityId="") => {
    return getApiWithToken(`/admin/getCategoryList?bookingType=${bookingType}&countryId=${countryId}&cityId=${cityId}`)
}

export const updateVehicleCategoryApi = (payload) => {
    return putApiWithToken(`/admin/updateVehicleCategory`, payload)
}

export const updateVehicleCategoryStatusApi = (payload) => {
    return putApiWithToken(`/admin/updateVehicleCategoryStatus`, payload)
}

export const getCategoryMakeAndModelApi = (bookingType = "") => {
    return getApiWithToken(`/admin/getCategoryMakeAndModel?bookingType=${bookingType}`)
}

export const addCategoryMakeAndModelApi = (payload) => {
    return postApiWithToken(`/admin/addCategoryMakeAndModel`, payload)
}

export const updateMakeAndModelStatusApi = (payload) => {
    return putApiWithToken(`/admin/updateMakeAndModelStatus`, payload)
}

export const getRidesListApi = (limit = "", page = "", search = "", type="", userId="") => {
    return getApiWithToken(`/admin/getRidesList?search=${search}&page=${page}&limit=${limit}&type=${type}&userId=${userId}`)
}

export const getCustomerDetailsApi = (userId="") => {
    return getApiWithToken(`/admin/customerDetails?id=${userId}`)
}

export const foodOrdersListApi = (limit = "", page = "", search = "") => {
    return getApiWithToken(`/admin/foodOrdersList?search=${search}&page=${page}&limit=${limit}`)
}

export const getCuisinesListApi = (limit = "", page = "") => {
    return getApiWithToken(`/admin/cuisinesList?page=${page}&limit=${limit}`)
}

export const addOrUpdateCusineApi = (payload) => {
    return postApiWithToken(`/admin/addOrUpdateCusine`, payload)
}

export const getMenuItemCategoryApi = (limit = "", page = "") => {
    return getApiWithToken(`/admin/getMenuItemCategory?page=${page}&limit=${limit}`)
}

export const addOrUpdateMenuItemCategoryApi = (payload) => {
    return postApiWithToken(`/admin/addOrUpdateMenuItemCategory`, payload)
}

export const uploadFileApi = (payload) => {
    return imgUploadApi(`/admin/uploadFile`, payload)
}

export const updateCuisineStatusApi = (payload) => {
    return putApiWithToken(`/admin/updateCuisineStatus`, payload)
}

export const updateMenuItemCategoryStatusApi = (payload) => {
    return putApiWithToken(`/admin/updateMenuItemCategoryStatus`, payload)
}

export const addCityWithTax = (payload) => {
    return postApiWithToken('/admin/addCity', payload);
}

export const updateCityApi = (payload) => {
    return putApiWithToken('/admin/updateCity', payload);
}

export const deleteCityApi = (payload) => {
    return deleteApi('/admin/deleteCity', payload);
}

export const citySuggestionsApi = (city = "", countryCode="") => {
    return getApiWithToken(`/admin/citySuggestions?city=${city}&countryCode=${countryCode}`);
}


export const addOrUpdateServieAreaCitiesApi = (payload) => {
    return postApiWithToken(`/admin/addOrUpdateServieAreaCities`,payload);
}

export const deleteServiceAreaCityApi = (payload) => {
    return deleteApi(`/admin/deleteServiceAreaCity`, payload);
}

export const enableOrDisableServiceAreaCountryApi = (payload) => {
    return putApiWithToken(`/admin/enableOrDisableServiceAreaCountry`, payload);
}

export const surgePeriodListApi = (categoryId="", type="") => {
    return getApiWithToken(`/admin/surgePeriodList?categoryId=${categoryId}&type=${type}`);
}

export const createOrUpdateSurgePeriodApi = (payload) => {
    return postApiWithToken(`/admin/createOrUpdateSurgePeriod`,payload);
}

export const updateSurgePeriodStatus = (payload) => {
    return putApiWithToken(`/admin/updateSurgePeriodStatus`, payload)
}

export const enableOrDisableSurgePeriodApi = (payload) => {
    return putApiWithToken(`/admin/enableOrDisableSurcharePeriod`, payload)
}

export const getUserRidesListApi = (page = "", limit= "", filter="all", userId="") => {
    return getApiWithToken(`/admin/getUserRidesList?page=${page}&limit=${limit}&filter=${filter}&userId=${userId}`);
}

export const cityListApi = (city = "") => {
    return getApiWithToken(`/admin/cityList?city=${city}`);
}

export const countryListApi = (search = "") => {
    return getApiWithToken(`/admin/countryList?search=${search}`);
}

export const serviceAreaCitiesApi = (countryId = "", search = "") => {
    return getApiWithToken(`/admin/serviceAreaCities?countryId=${countryId}&search=${search}`);
}
export const serviceAreaCountriesApi  = (search = "") => {
    return getApiWithToken(`/admin/serviceAreaCountries?search=${search}`);
}

export const enableOrDisableServiceAreaCitiesApi = (payload) => {
    return putApiWithToken(`/admin/enableOrDisableServiceAreaCities`, payload)
}

export const cityByCountryApi = (search = "",countryId="") => {
    return getApiWithToken(`/admin/cityByCountry?search=${search}${countryId ? `&countryId=${countryId}`: ''}`);
}

export const bonusListApi = (categoryId = "", type = "") => {
    return getApiWithToken(`/admin/bonusList?categoryId=${categoryId}&type=${type}`);
}

export const addOrUpdateBonus = (payload) => {
    return postApiWithToken(`/admin/addOrUpdateBonus`,payload);
}

export const updateBonusStatus = (payload) => {
    return postApiWithToken(`/admin/updateBonusStatus`,payload);
}

export const updateRestaurantFeeOnOffApi = (payload) => {
    return postApiWithToken(`/admin/updateRestaurantFeeOnOff`,payload);
}

export const SubscriptionDriverApi = (categoryId = "", type = "") => {
    return getApiWithToken(`/admin/subscriptionList?categoryId=${categoryId}&type=${type}`);
}

export const addOrUpdateSubscriptionDriverApi = (payload) => {
    return postApiWithToken(`/admin/addOrUpdateSubscription`,payload);
}

export const updateSubscriptionDriverStatus = (payload) => {
    return postApiWithToken(`/admin/updateSubscriptionStatus`,payload);
}

export const updateSubscriptionFeeAndCommissionFeeApi = (payload) => {
    return putApiWithToken(`/admin/updateSubscriptionAndCommissionFee`, payload)
}

export const getDefaultCategoryListApi = (bookingType = "", rentalVehicleType = "") => {
    return getApiWithToken(`/admin/defaultCategoryList?bookingType=${bookingType}&rentalVehicleType=${rentalVehicleType}`)
}

export const defaultCategoryListOptionsApi = (bookingType = "yiinoRide") => {
    return getApiWithToken(`/admin/defaultCategoryListOptions?bookingType=${bookingType}`)
}

export const addOrUpdateDefaultCategoryApi = (payload) => {
    return postApiWithToken(`/admin/addOrUpdateDefaultCategory`, payload)
}

export const updateDefaultCategoryApi = (payload) => {
    return putApiWithToken(`/admin/updateDefaultCategoryStatus`, payload)
}

export const defaultCategoryByIdApi = (id='') => {
    return getApiWithToken(`/admin/defaultCategoryById?id=${id}`)
}

export const getCategoryByIdApi = (id='') => {
    return getApiWithToken(`/admin/getCategoryById?id=${id}`)
}

export const partnerEarningWithdrawRequestApi = (id='') => {
    return getApiWithToken(`/admin/partnerEarningWithdrawRequest?id=${id}`)
}

export const changePartnerEarningWithdrawRequestStatusApi = (payload) => {
    return putApiWithToken(`/admin/changePartnerEarningWithdrawRequestStatus`, payload)
}
// ------------ global settings --------------------

export const getAppVersionDataApi = () => {
    return getApiWithToken(`/admin/getAppVersionData`)
}

export const getAllAppBannerImagesApi = (type = "") => {
    return getApiWithToken(`/admin/getAllAppBannerImages?type=${type}`)
}

export const updateAppBannerSerialNoApi = (payload) => {
    return putApiWithToken(`/admin/updateAppBannerSerialNo`, payload)
}

export const updateAppVersionDataApi = (payload) => {
    return putApiWithToken(`/admin/updateAppVersionData`, payload)
}

export const addOrUpdateAppBannerImagesApi = (payload) => {
    return postApiWithToken(`/admin/addOrUpdateAppBannerImages`, payload)
}

export const deleteAppBannerImageApi = (payload) => {
    return deleteApi(`/admin/deleteAppBannerImage`, payload)
}


// ------------ Administrator --------------------

export const getAllAdminListApi = (page="", limit="", search= "" ) => {
    return getApiWithToken(`/admin/administrators?page=${page}&limit=${limit}&search=${search}`)
}

export const addOrUpdateAdministratorsApi = (payload) => {
    return postApiWithToken(`/admin/addOrUpdateAdministrators`, payload)
}

export const enableOrdisableAdministratorApi = (payload) => {
    return putApiWithToken(`/admin/enableOrdisableAdministrator`, payload)
}

// ----------  Package ----------------------------------------
export const addOrUpdatePackageDefaultCategoryApi = (payload) => {
    return postApiWithToken(`/admin/addOrUpdateDefaultPackageCategory`, payload)
}

// ----------  Rental ----------------------------------------
export const addOrUpdateRentalDefaultCategoryApi = (payload) => {
    return postApiWithToken(`/admin/addOrUpdateDefaultRentalsCategory`, payload)
}


// ----------  Sales Agent ----------------------------------------
export const salesAgentByIdApi = (id) => {
    return getApiWithToken(`/admin/salesAgentById?id=${id}`)
}


export const updateSalesAgentRatesApi = (payload) => {
    return putApiWithToken(`/admin/updateSalesAgentRates`, payload)
}

export const approveOrRejectSalesAgentDocumentsApi = async (data) => {
    return putApiWithToken(`/admin/approveOrRejectSalesAgentDocumentsApi`, data)
}

export const addOrUpdateReferralDetailsApi = (payload) => {
    return postApiWithToken(`/admin/addOrUpdateReferralDetails`, payload)
}

export const getReferralDetailsApi = (countryId="", cityId="") => {
    return getApiWithToken(`/admin/getReferralDetails?countryId=${countryId}&cityId=${cityId}`)
}

export const getReferralDataApi = (country="", city="") => {
    return getApiWithToken(`/admin/getReferralData?country=${country}&city=${city}`)
}

export const enableOrDisableBonusApi = (payload) => {
    return putApiWithToken(`/admin/enableOrDisableBonus`, payload)
}