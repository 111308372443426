import React, { useState } from 'react'
import TimePicker from 'react-time-picker'
import Spinwheel from '../Spinwheel'
import { debounce } from 'lodash';
import { cityByCountryApi, countryListApi } from '../../../utils/ApiEndpointFunctions';
import AsyncSelect from 'react-select/async';

function SurChargePriodFormModal(props) {
  const { formik, addLoader, modalId } = props

  // console.log(formik?.values,formik?.touched)

  const [selectCon, setSelectCon] = useState();

  const filterCites = async (inputValue, cb) => {
    try {
      if(selectCon){

        const res = await cityByCountryApi(inputValue, selectCon)
        if (res.status === 200) {
          let cityOption = res.data?.data?.map(ele => ({ label: ele.name, value: ele.id }))
          cb(cityOption);
        }
      }
    } catch (error) {
      console.log(error)
    }
  };

  const cityOnChange = debounce(filterCites, 1000);

  const cityOptions = (inputValue, callback) => {
    cityOnChange(inputValue, callback);
  }

  const filterCountry = async (inputValue, cb) => {
    try {
      const res = await countryListApi(inputValue)
      if (res.status === 200) {
        let cityOption = res.data?.data?.map(ele => ({ label: ele.name, value: ele.id }))
        cb(cityOption);
      }
    } catch (error) {
      console.log(error)
    }
  };

  const countryOnChange = debounce(filterCountry, 1000);

  const countryOptions = (inputValue, callback) => {
    countryOnChange(inputValue, callback);
  }



  const handleCityChange = (city) => {
   
    formik.setFieldValue('cityId', city);
  }

  const handleCountryChange = (country) => {
    setSelectCon(country.value)
    formik.setFieldValue('countryId', country);
    formik.setFieldValue('cityId', "");
  }

  


  return (
    <>
      <div
        className="modal fade"
        id={modalId}
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button
                id="confirmModalCloseBtn"
                type="button"
                className="btn-close block-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body pe-0 ps-0">
              <div className="container">
                <h2>Add surge Period</h2>
                <form onSubmit={formik?.handleSubmit} >

                  <div className='row '>

                    {/* <div className="mb-3">
                      <label htmlFor="city" className="form-label">Country</label>
                      <AsyncSelect cacheOptions loadOptions={countryOptions} defaultOptions value={formik?.values?.countryId} onChange={handleCountryChange} onBlur={formik?.handleBlur} />
                      {formik?.touched?.countryId && formik?.errors?.countryId ? (<div className='req-error'>{formik?.errors?.countryId}</div>) : null}
                    </div>

                    <div className="mb-3">
                      <label htmlFor="city" className="form-label">City</label>
                      <AsyncSelect cacheOptions loadOptions={cityOptions} isDisabled={!selectCon} defaultOptions value={formik?.values?.cityId} onChange={handleCityChange} onBlur={formik?.handleBlur} />
                      {formik?.touched?.cityId && formik?.errors?.cityId ? (<div className='req-error'>{formik?.errors?.cityId}</div>) : null}
                    </div> */}

                    <div className="mb-3 col">
                      <label htmlFor="from" className="form-label">From</label> <br />
                      <TimePicker
                        id="time-picker"
                        className={"w-100"}
                        clockClassName={"react-clock"}
                        format="h:m a"
                        isOpen={false}
                        clockIcon={null}
                        closeClock={true}
                        hourPlaceholder='hh'
                        minutePlaceholder='mm'
                        secondPlaceholder='ss'
                        value={formik?.values?.from}
                        name="from"
                        onChange={(ele) => {formik?.setFieldValue("from", ele)}}
                        onBlur={() => {formik?.setFieldTouched("from",true)}}
                      />
                      {formik?.touched?.from && formik?.errors?.from ? (<div className='req-error'>{formik?.errors?.from}</div>) : null}
                    </div>

                    <div className="mb-3 col ">
                      <label htmlFor="to" className="form-label">To</label><br />
                      <TimePicker
                        id="time-picker"
                        clockClassName={"react-clock"}
                        className={"w-100"}
                        format="h:m a"
                        isOpen={true}
                        clockIcon={null}
                        closeClock={false}
                        hourPlaceholder='hh'
                        minutePlaceholder='mm'
                        secondPlaceholder='ss'
                        name='to'
                        value={formik?.values?.to}
                        onBlur={(ele) => {formik?.setFieldTouched("to",true)}}
                        onChange={(ele) => formik?.setFieldValue("to", ele)}
                      />
                      {formik?.touched?.to && formik?.errors?.to ? (<div className='req-error'>{formik?.errors?.to}</div>) : null}
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="multiplier" className="form-label">Multiplier (%)</label>
                    <input type="text" className="form-control" id="multiplier" placeholder="Enter multiplier" name='multiplier' value={formik?.values?.multiplier} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                    {formik?.touched?.multiplier && formik?.errors?.multiplier ? (<div className='req-error'>{formik?.errors?.multiplier}</div>) : null}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="periodName" className="form-label">Period name</label>
                    <input type="text" className="form-control" id="periodName" placeholder="Enter period name" name='periodName' value={formik?.values?.periodName} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                    {formik?.touched?.periodName && formik?.errors?.periodName ? (<div className='req-error'>{formik?.errors?.periodName}</div>) : null}
                  </div>
                  {/* <div className='mb-3'>
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Enable</label>
                    <div className="form-check form-switch ">
                      <input className="form-check-input" type="checkbox" checked={formik?.values?.isEnabled} role="switch" id="flexSwitchCheckDefault" onChange={formik?.handleChange} />
                    </div>
                  </div> */}
                  <button type="submit" disabled={addLoader} className="btn btn-primary">
                    {addLoader ? <Spinwheel sizeClass={"spin-small"} /> : "Submit"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SurChargePriodFormModal