import React, { useEffect, useState } from 'react'
import Spinwheel from '../Spinwheel'
import { cityByCountryApi, countryListApi, defaultCategoryListOptionsApi } from '../../../utils/ApiEndpointFunctions';
import ReactSelect from 'react-select';

function RewardAndReferralModal(props) {
  const { formik, addLoader, modalId,type } = props;  

  const [countryOptions, setCountryOptions] = useState([])
  const [cityOptions, setCityOptions] = useState([])
  const [defaultCategoryOptions, setDefaultCategoryOptions] = useState([])

  const filterCites = async (countryId) => {
    try {
      const res = await cityByCountryApi("", countryId)
      if (res.status === 200) {
        let cityOption = res.data?.data?.map(ele => ({ label: ele.name, value: ele.id }))
        setCityOptions(cityOption);
      }

    } catch (error) {
      console.log(error)
    }
  };

  const filterCountry = async (inputValue) => {
    try {
      const res = await countryListApi(inputValue)
      if (res.status === 200) {
        let cityOption = res.data?.data?.map(ele => ({ label: ele.name, value: ele.id }))
        setCountryOptions(cityOption);
      }
    } catch (error) {
      console.log(error)
    }
  };


  const handleCountryChange = (country) => {
   
    setCityOptions([])
    formik.setFieldValue('countryId', country);
    if(!formik?.values?.id){
       formik.setFieldValue('cityId', []);
    }
   //  formik.setFieldValue('category', []);
    filterCites(country.value)
  }

  

  const handleCityChange = (city) => {
      formik.setFieldValue('cityId',city)
  }

  const handleCategoryChange = (category) => {
      formik.setFieldValue('category', [...category])
  }



  useEffect(() => {
    filterCountry()
  },[])


  return (
    <>
      <div
        className="modal fade"
        id={modalId}
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button
                id="confirmModalCloseBtn"
                type="button"
                className="btn-close block-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body pe-0 ps-0">
              <div className="container">
                <h2>{formik?.values?.id ? "Update" : "Add"} </h2>
                <form onSubmit={formik?.handleSubmit} >
                  {!formik?.values?.id && <>
                    <div className="mb-3">
                      <label htmlFor="city" className="form-label">Country</label>
                      <ReactSelect options={countryOptions} value={formik?.values?.countryId} onChange={handleCountryChange} onBlur={formik?.handleBlur} noOptionsMessage={() => 'No Countries Available'} />
                      {formik?.touched?.countryId && formik?.errors?.countryId ? (<div className='req-error'>{formik?.errors?.countryId}</div>) : null}
                    </div>

                    <div className="mb-3">
                      <label htmlFor="city" className="form-label">City</label>
                      <ReactSelect options={cityOptions} isDisabled={!formik?.values?.countryId} value={formik?.values?.cityId} onChange={handleCityChange} onBlur={formik?.handleBlur} noOptionsMessage={() => 'No Cities Available'} />
                      {formik?.touched?.cityId && formik?.errors?.cityId ? (<div className='req-error'>{formik?.errors?.cityId}</div>) : null}
                    </div>
                  </>}
                  <hr />
                  <h3>Referral setting</h3>
                  <hr />
                  <div className='row row-cols-2'>

                    <div className='d-flex flex-column mb-3'>
                      <label htmlFor='minTrips' className=" col-form-label">Min trip</label>
                      <div className="">
                        <input type="text" className="form-control" id='minTrips' name='minTrips' placeholder="0" onChange={formik?.handleChange} onBlur={formik?.handleBlur} value={formik?.values?.minTrips} />
                        {formik?.touched?.minTrips && formik.errors?.minTrips ? (<div className='req-error'>{formik?.errors?.minTrips}</div>) : null}
                      </div>
                    </div>
                    {type == "referral" && <div className='d-flex flex-column mb-3'>
                      <label htmlFor='minReferral' className=" col-form-label">Min referral</label>
                      <div className="">
                        <input type="text" className="form-control" id='minReferral' placeholder="0" name='minReferral' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik?.values?.minReferral} />
                        {formik.touched?.minReferral && formik.errors?.minReferral ? (<div className='req-error'>{formik?.errors?.minReferral}</div>) : null}
                      </div>
                    </div>}


                    <div className='d-flex flex-column mb-3'>
                      <label htmlFor='minTripCharges' className=" col-form-label">Min trip charges</label>
                      <div className="">
                        <input type="text" className="form-control" id='minTripCharges' placeholder="0" name='minTripCharges' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik?.values?.minTripCharges} />
                        {formik?.touched?.minTripCharges && formik?.errors?.minTripCharges ? (<div className='req-error'>{formik?.errors?.minTripCharges}</div>) : null}
                      </div>
                    </div>

                    <div className='d-flex flex-column mb-3'>
                      <label htmlFor='referralPrice' className=" col-form-label">Reward price</label>
                      <div className="">
                        <input type="text" className="form-control" id='referralPrice' placeholder="0" name='referralPrice' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik?.values?.referralPrice} />
                        {formik?.touched?.referralPrice && formik?.errors?.referralPrice ? (<div className='req-error'>{formik?.errors?.referralPrice}</div>) : null}
                      </div>
                    </div>

                    <div className='d-flex flex-column mb-3'>
                      <label htmlFor='validDays' className=" col-form-label">Valid Days</label>
                      <div className="">
                        <input type="text" className="form-control" id='validDays' placeholder="0" name='validDays' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik?.values?.validDays} />
                        {formik?.touched?.validDays && formik?.errors?.validDays ? (<div className='req-error'>{formik?.errors?.validDays}</div>) : null}
                      </div>
                    </div>

                    <div className='d-flex flex-column mb-3'>
                      <label htmlFor='validRewardDays' className=" col-form-label">Valid Reward Days</label>
                      <div className="">
                        <input type="text" className="form-control" id='validRewardDays' placeholder="0" name='validRewardDays' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik?.values?.validRewardDays} />
                        {formik?.touched?.validRewardDays && formik?.errors?.validRewardDays ? (<div className='req-error'>{formik?.errors?.validRewardDays}</div>) : null}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <h3>Signup reward setting</h3>
                  <hr />

                  <div className='row row-cols-2'>
                    <div className='d-flex flex-column mb-3'>
                      <label htmlFor='firstNoOf' className=" col-form-label">First no. of</label>
                      <div className="">
                        <input type="text" className="form-control" id='firstNoOf' name='firstNoOf' placeholder="0" onChange={formik?.handleChange} onBlur={formik?.handleBlur} value={formik?.values?.firstNoOf} />
                        {formik?.touched?.firstNoOf && formik.errors?.firstNoOf ? (<div className='req-error'>{formik?.errors?.minTrips}</div>) : null}
                      </div>
                    </div>

                    <div className='d-flex flex-column mb-3'>
                      <label htmlFor='signupMinTrips' className=" col-form-label">Min trip</label>
                      <div className="">
                        <input type="text" className="form-control" id='signupMinTrips' name='signupMinTrips' placeholder="0" onChange={formik?.handleChange} onBlur={formik?.handleBlur} value={formik?.values?.signupMinTrips} />
                        {formik?.touched?.signupMinTrips && formik.errors?.signupMinTrips ? (<div className='req-error'>{formik?.errors?.signupMinTrips}</div>) : null}
                      </div>
                    </div>

                    <div className='d-flex flex-column mb-3'>
                      <label htmlFor='signupMinTripCharges' className=" col-form-label">Min trip charges</label>
                      <div className="">
                        <input type="text" className="form-control" id='signupMinTripCharges' placeholder="0" name='signupMinTripCharges' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik?.values?.signupMinTripCharges} />
                        {formik?.touched?.signupMinTripCharges && formik?.errors?.signupMinTripCharges ? (<div className='req-error'>{formik?.errors?.signupMinTripCharges}</div>) : null}
                      </div>
                    </div>

                    <div className='d-flex flex-column mb-3'>
                      <label htmlFor='signupRewardPrice' className=" col-form-label">Reward price</label>
                      <div className="">
                        <input type="text" className="form-control" id='signupRewardPrice' placeholder="0" name='signupRewardPrice' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik?.values?.signupRewardPrice} />
                        {formik?.touched?.signupRewardPrice && formik?.errors?.signupRewardPrice ? (<div className='req-error'>{formik?.errors?.signupRewardPrice}</div>) : null}
                      </div>
                    </div>

                    <div className='d-flex flex-column mb-3'>
                      <label htmlFor='singupValidDays' className=" col-form-label">Valid Days</label>
                      <div className="">
                        <input type="text" className="form-control" id='singupValidDays' placeholder="0" name='singupValidDays' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik?.values?.singupValidDays} />
                        {formik?.touched?.singupValidDays && formik?.errors?.singupValidDays ? (<div className='req-error'>{formik?.errors?.singupValidDays}</div>) : null}
                      </div>
                    </div>

                    <div className='d-flex flex-column mb-3'>
                      <label htmlFor='signupValidRewardDays' className=" col-form-label">Valid Reward Days</label>
                      <div className="">
                        <input type="text" className="form-control" id='signupValidRewardDays' placeholder="0" name='signupValidRewardDays' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik?.values?.signupValidRewardDays} />
                        {formik?.touched?.signupValidRewardDays && formik?.errors?.signupValidRewardDays ? (<div className='req-error'>{formik?.errors?.signupValidRewardDays}</div>) : null}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className='row row-cols-2'>
                  <div className='d-flex flex-column mb-3'>
                    <label htmlFor='referralOnOff' className=" col-form-label">Referral reward On/off</label>
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" role="switch" checked={formik?.values?.referralOnOff} id="referralOnOff" onChange={() => formik?.setFieldValue("referralOnOff", !Boolean(formik?.values?.referralOnOff))} />
                      {formik?.touched?.referralOnOff && formik?.errors?.referralOnOff ? (<div className='req-error'>{formik?.errors?.referralOnOff}</div>) : null}
                    </div>
                  </div>
                  
                  <div className='d-flex flex-column mb-3'>
                    <label htmlFor='signupOnOff' className=" col-form-label">Signup reward On/off</label>
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" role="switch" checked={formik?.values?.signupOnOff} id="signupOnOff" onChange={() => formik?.setFieldValue("signupOnOff", !Boolean(formik?.values?.signupOnOff))} />
                      {/* {formik?.touched?.signupOnOff && formik?.errors?.signupOnOff ? (<div className='req-error'>{formik?.errors?.signupOnOff}</div>) : null} */}
                    </div>
                  </div>
                  </div>
                  {formik?.touched?.signupOnOff && formik?.errors?.signupOnOff ? (<div className='req-error'>{formik?.errors?.signupOnOff}</div>) : null}


                  <button type="submit" disabled={addLoader} className="btn btn-primary">
                    {addLoader ? <Spinwheel sizeClass={"spin-small"} /> : "Submit"}
                  </button>
                </form>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default RewardAndReferralModal