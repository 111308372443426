import React from 'react'

const ConfirmationModal = ({modalId, confirmStatus,handleConfirm, message}) => {

    return (
        <div
            className="modal fade"
            id={modalId ?? "confirmationModal"}
            tabIndex="-1"
            aria-labelledby="deleteModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-sm modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header border-0">
                        <button
                            id = "confirmModalCloseBtn"
                            type="button"
                            className="btn-close block-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body pe-0 ps-0 block-user">
                        <img src="/images/blockuser.png" alt="" width={202} srcSet="" />
                        <h1 className="mb-0">
                            {message ?? <>Are you sure you want to
                            <br /> {confirmStatus.status === 'approved' ? 'approve' : confirmStatus.status === 'rejected' ? 'reject':""} this User?</>}
                        </h1>
                    </div>
                    <div className="modal-footer pe-0 ps-0 border-0 justify-content-center pt-0 mb-3">
                        <button
                            type="button"
                            className="btn-custom w-auto"
                            onClick={handleConfirm}
                            data-bs-dismiss="modal"
                        >
                            CONFIRM
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmationModal