import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { useAdminContext } from '../../../context/adminContext';
import { getRidesListApi } from '../../../utils/ApiEndpointFunctions';
import { capitalizeWords } from '../../../utils/HelperFuncitons';
import LimitPagination from '../../common/LimitPagination';
import RidesDetailModal from '../../common/Modal/RidesDetailModal';
import CommonTable from '../../common/Table/CommonTable'

function Rides({ userId, type }) {
  const [loader, setLoader] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(0);
  const [selectedRide, setSelectedRide] = useState()
  const [activeTab, setActiveTab] = useState("rides")
  const rideDetailModalId = "rideDetailModal"
  // const limit = 10;
  const {limit} = useAdminContext();


  const rideStatus = (status, cancelledBy) => {
    return (
      status === "pending" || !status ? "Requested" :
        status === "accepted" ? "On route to pickup" :
          status === "arrived" ? "Arrived at pickup point" :
            status === "startRide" ? "In-progress" :
              status === "completed" ? "Completed" :
                status === "cancelled" ? `Cancelled ${cancelledBy ? "By " + capitalizeWords(cancelledBy) : ""} ` : "-")
  }

  const hireRequestStatus = (status, cancelledBy) => {
    return (
      !status ? "Requested" :
        status === "accepted" ? "Accepted" :
          status === "ongoing" ? "In-Progress" :
            status === "vehicleDelivered" ? "Vehicle Delivered To user" :
              status === "completed" ? "Completed" :
                status === "cancelled" ? `Cancelled ${cancelledBy ? "By " + capitalizeWords(cancelledBy) : ""} ` : "-")
  }

  const restaurantOrderStatus = (status, cancelledBy) => {
    return (
      status === "pending" ? "Requested" :
        status === "preparing" ? "Preparing" :
          status === "ready" ? "Ready" :
            status === "pickedUp" ? "Picked Up by Driver" :
              status === "delivered" ? "Delivered" :
                status === "rejected" ? "Rejected By Restaurant" :
                  status === "cancelled" ? `Cancelled ${cancelledBy ? "By " + capitalizeWords(cancelledBy) : ""} ` : "-")
  }

  const getAllrides = async (selectedPage, limit) => {
    try {
      setLoader(true)
      const res = await getRidesListApi(limit, selectedPage, search, activeTab, (type === "customerDetails" ? userId: ''))
      if (res.status === 200 || res.status === 201) {
        const usersData = res?.data?.data?.rides;
        const totalLength = res?.data?.data?.totalLength;
        setTotalCount(totalLength);
        setPage(Math.ceil(totalLength / limit));
        setTableData(usersData);
        setLoader(false);
      }
    } catch (error) {
      console.log(error)
    }
    setLoader(false)
  }

  const handlePageClick = (event) => {
    let currentPage = event.selected + 1;
    getAllrides(currentPage,limit)
    setSelectedPage(currentPage)

  };

  const baseHeaderData = [
    { name: "Id", className: "col-1", key: "id" },
    ...(type !== "customerDetails" ? [{ name: "Customer", className: "col-1", key: "customer" }] : []),
    { name: "Fare", className: "col-1", key: "charges" },
    { name: "Payment Mode", className: "col-1", key: "paymentMode" },
    { name: "Status", className: "col-1", key: "rideStatus" },
    { name: "Action", className: "col-1", key: "action" }
  ];
  
  const specificHeaderData = {
    rides: [
      { name: "Driver", className: "col-1", key: "driver" },
      { name: "Pickup", className: "col-1", key: "pickup" },
      { name: "DropOff", className: "col-1", key: "dropoff" }
    ],
    packageDelivery: [
      { name: "Receiver", className: "col-1", key: "receiver"},
      { name: "Driver", className: "col-1", key: "driver" },
      { name: "Pickup", className: "col-1", key: "pickup" },
      { name: "DropOff", className: "col-1", key: "dropoff" },
      { name: "Type", className: "col-1", key: "packageType" },
    ],
    hire: [
      { name: "Vendor", className: "col-1", key: "vendor" },
      { name: "Pickup", className: "col-1", key: "pickup" },
      { name: "DropOff", className: "col-1", key: "dropoff" }
    ],
    foodOrders: [
      { name: "Restaurant", className: "col-1", key: "restaurant" },
      { name: "Delivery Partner", className: "col-1", key: "deliveryPartner" }
    ]
  };
  
  const headerData = [
    ...baseHeaderData.slice(0, 2),
    ...(specificHeaderData[activeTab]),
    ...baseHeaderData.slice(2)
  ];
  
  const RideTableData = tableData.map((item) => {

    return ({
    id: item.id,
    customer: `${item?.user?.firstName ? capitalizeWords(item?.user?.firstName) : ""} ${item?.user?.lastName ? capitalizeWords(item?.user?.lastName) : ""}`,
    driver: item?.bookingDetail?.partnerUser?.partnerUserDetails?.[0]?.name,
    vendor: item?.bookingDetailsRental?.partnerUser?.partnerUserDetails?.[0]?.name,
    pickup: (
      <span title={item?.pickup}>{item?.pickup ? item?.pickup?.slice(0, 10) + (item?.pickup?.length > 10 ? '...' : "") : "-"}</span>
    ),
    dropoff: (
      <span title={item?.dropOff}>{item?.dropOff ? item?.dropOff?.slice(0, 10) + (item?.dropOff?.length > 10 ? '...' : "") : "-"}</span>
    ),
    receiver: item?.receiverName,
    restaurant: item?.restaurant?.name,
    deliveryPartner: item?.driverDetails?.name,
    charges: activeTab === "foodOrders" ? item?.totalAmount ?? "" : item?.charges ?? "",
    paymentMode: item?.paymentMode ? capitalizeWords(item?.paymentMode) : "-",
    packageType: item?.bookingType === "yiinoPackage" ? "Yiino Package" : "Yiino Food",
    rideStatus: activeTab === "hire" ? hireRequestStatus(item.isCancelled ? "cancelled" : item?.bookingDetailsRental?.status, (item.bookingDetailsRental?.role ?? "user"))
                : activeTab === "foodOrders" ? restaurantOrderStatus(item?.status, item?.cancelledBy)
                  : rideStatus(item.isCancelled ? "cancelled" : item?.bookingDetail?.status, item.bookingDetail?.role),
    action: (
      <div className="dropdown">
        <button
          className=" border-0 bg-white"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-three-dots-vertical"
            viewBox="0 0 16 16"
          >
            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
          </svg>
        </button>
        <ul
          className="dropdown-menu"
          style={{ zIndex: "1000" }}
        >
          <li className="dropdown-item">
            <div
              data-bs-toggle="modal"
              data-bs-target={`#${rideDetailModalId}`}
              style={{ cursor: "pointer" }}
              onClick={() => { setSelectedRide(item) }}
            >
              View
            </div>
          </li>
        </ul>
      </div>
    )
  })})


  useEffect(() => {
    getAllrides(selectedPage,limit);
  }, [activeTab])

  const tabData = [
    { name: "Rides", tab: "rides" },
    { name: "Package Delivery", tab: "packageDelivery" },
    { name: "Hire/ Rental", tab: "hire" },
    { name: "Food Orders", tab: "foodOrders" },
  ]

  const handleChangeTab = (tab) => {
    if(loader) return;
    setSelectedPage(1)
    setTableData([])
    setTotalCount(null)
    setActiveTab(tab)
  }

  return (

    <>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            {/* <BreadCrumb /> */}
            <div className="main-content">
              {/* HEADER */}
              <div className="row">
                <>
                  <ul className="nav nav-tabs">
                    {tabData.map((el, index) => <li key={index} className="nav-item ms-3">
                      <button
                        className={`nav-link ${(activeTab === el.tab) ? 'active' : ''}`}
                        onClick={() => handleChangeTab(el.tab)}
                      >
                        {el.name}
                      </button>
                    </li>)}

                  </ul>
                  <div className="card">
                    <div className="d-flex justify-content-between flex-wrap align-items-center mt-3 mt-lg-0 py-4">
                      <LimitPagination getData={getAllrides} />
                      <div style={{ display: "-webkit-inline-box" }}>
                      </div>
                    </div>
                    <CommonTable headers={headerData} data={RideTableData} loading={loader} />
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>

      {totalCount > limit ? (
        <div className="d-flex justify-content-center">
          <ReactPaginate
            nextLabel="Next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            pageCount={page}
            previousLabel="< Previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName={"active"}
            forcePage={selectedPage - 1}
          />
        </div>
      ) : (
        ""
      )}

      <RidesDetailModal
        rideDetail={selectedRide}
        modalId={rideDetailModalId}
        activeTab={activeTab}
        rideStatus={rideStatus}
        hireRequestStatus={hireRequestStatus}
        restaurantOrderStatus={restaurantOrderStatus}
      />
    </>

  )
}

export default Rides