import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { adminChangePartnerApprovalStatus, updateSalesAgentRatesApi, salesAgentByIdApi, approveOrRejectSalesAgentDocumentsApi } from '../../../utils/ApiEndpointFunctions';
import Spinwheel from '../../common/Spinwheel';
import { capitalizeWords, dateFormat } from '../../../utils/HelperFuncitons';
import { S3_URL } from '../../../utils/BaseUrl';
import ConfirmationModal from '../../common/ConfirmationModal';
import toast from 'react-hot-toast';
import CommonTable from '../../common/Table/CommonTable';
import { useFormik } from 'formik';
import { salesAgentInitialValues, salesAgentRatesValidationSchema } from '../../common/Validation';
import PdfSvgIcon from '../../common/PdfSvgIcon';
import ImageViewModal from '../../common/Modal/ImageViewModal';
import DatePicker from "react-datepicker";



const SalesAgentDetails = () => {
    const params = useParams();
    const [id] = useState(params?.id)
    const [profileDetails, setProfiledetails] = useState({});
    const [salesAgentDetails, setSalesAgentDetails] = useState({});
    const [bankDetails, setBankDetails] = useState([]);
    const [rateLoader, setRateLoader] = useState(false);
    const [loader, setLoader] = useState(true);
    const [activeTab, setActiveTab] = useState('driver');
    const [confirmStatus, setConfirmStatus] = useState({ status: "", userId: "", role: "salesAgent" });
    const [selectDocument, setSelectDocument] = useState({ status: "", keyName: "", validDate: "", restaurantId: "" });
    const [selectedImg, setSelectedImg] = useState("")
    const confirmationModalId2 = "confirmationModal2"

    const fetchSalesAgentDetails = async () => {
        try {
            const res = await salesAgentByIdApi(id);
            if (res.status === 200) {
                const { id, role, phone, salesAgent, bankDetails, partnerUserDetails } = res.data.data.details
              
                setProfiledetails({ id, phone, role, ...partnerUserDetails[0], approvalStatus: salesAgent.status });
                setSalesAgentDetails(salesAgent);
                setBankDetails(bankDetails)
                rateFormik.setValues({ id: id, rewardPercentage: salesAgent.rewardPercentage, from: salesAgent.createdAt, to: salesAgent.rewardExpiryDate, rewardOnOff: salesAgent.rewardOnOff });
            }
            setLoader(false);
        } catch (error) {
            setLoader(false);
            console.log(error);
        }
    };

    const handleChangeStatus = async () => {
        try {
            setLoader(true)
            await adminChangePartnerApprovalStatus(confirmStatus)
            fetchSalesAgentDetails();
            document.getElementById('confirmationModal').click()
        } catch (error) {
            toast.error(error?.response?.data?.message || "Something Went Wrong")
            setLoader(false)
        }
    }

    useEffect(() => {
        if (id) {
            fetchSalesAgentDetails();
        }
    }, [id]);

    const toggleTab = (tab) => {
        setActiveTab(tab);
    };

    const bankDetailsHeaderData = [
        { name: "Account Holder Namer", className: "list-sort text-muted", key: "name" },
        { name: "Bank Name", className: "list-sort text-muted", key: "bankName" },
        { name: "IFSC", className: "list-sort text-muted", key: "Ifsc" },
        { name: "Account Number", className: "list-sort text-muted", key: "accountNo" },
        { name: "Account Type", className: "list-sort text-muted", key: "accountType" },

    ]

    const bankDetailsTableData = bankDetails?.map((bnk, index) => ({
        name: profileDetails.name || "",
        bankName: bnk.name || "",
        Ifsc: bnk.Ifsc || "",
        accountNo: bnk.accountNo || "",
        accountType: bnk.accountType || "",
    }))

    const profileData = [
        { name: "Email", data: profileDetails.email, },
        { name: "Phone", data: profileDetails.phone, },
        { name: "Alternate Phone", data: profileDetails.alternateMobile, },
        { name: "NIN", data: profileDetails.NIN, },
        { name: "Status", data: capitalizeWords(profileDetails.approvalStatus), },
        { name: "Role", data: profileDetails.role?.map(el => capitalizeWords(el))?.join(", "), },
        { name: "Gender", data: profileDetails.gender, },
        { name: "City", data: profileDetails.city, },
        { name: "State", data: profileDetails.state, },
        { name: "Country", data: profileDetails.country, },
        { name: "Zip Code", data: profileDetails.zip, },
        { name: "Address", data: <div>{profileDetails.permanentAddress && `${profileDetails.permanentAddress}, `}{profileDetails.state && `${profileDetails.state}, `}{profileDetails.city && `${profileDetails.city}, `}{profileDetails.zip && `${profileDetails.zip}`}</div>, },

    ]

    const rateSubmit = async (values) => {
        setRateLoader(true)
        try {
            const res = await updateSalesAgentRatesApi(values)
            if (res.status === 200) {
                toast.success(res.data.message)
            }
        } catch (error) {
            toast.error("Failed to update rate")
        }
        setRateLoader(false)
        console.log(values)
    }

    const rateFormik = useFormik({
        initialValues: salesAgentInitialValues,
        validationSchema: salesAgentRatesValidationSchema,
        onSubmit: rateSubmit
    })

    const openModal = () => {
        document.getElementById('confirmationModal2Btn').click()
    }

    const handleChangeDocumentStatus = async () => {
        const promise = approveOrRejectSalesAgentDocumentsApi({ userId: id, keyName: selectDocument.keyName, status: selectDocument.status, restaurantId: selectDocument.restaurantId });
        toast.promise(
            promise,
            {
                loading: 'Updating document status...',
                success: (res) => {
                    if (res.status === 200) {
                        profileDetails.approvalStatus = res.data.salesAgentStatus
                        setSalesAgentDetails({ ...salesAgentDetails, [selectDocument.keyName]: res.data.docStatus })
                        return res.data.message || 'Success';
                    }
                },
                error: (err) => {
                    return err.message || 'Something Went Wrong'
                }
            }
        )
    };

    const DocumentDisplay = ({ title, document, setSelectedImg, status, keyName, validUpto, number, restaurantId }) => {

        if (!document) {
            return (
                <tr>
                    <td>{title}</td>
                    <td className='text-center'>{`${title} not available`}</td>
                </tr>
            );
        }

        const isPdf = document ? document.split(".").slice(-1)[0] === "pdf" : "";

        return (
            <tr>
                <td>{title}</td>
                <td className='text-center'>
                    {isPdf ? (
                        <Link to={`${S3_URL}/${document}`} className="text-primary">
                            <PdfSvgIcon width="100" height="100" />
                        </Link>
                    ) : (
                        <img
                            src={`${S3_URL}/${document}`}
                            alt={title}
                            data-bs-toggle="modal"
                            data-bs-target={`#imageViewModal`}
                            style={{ cursor: 'pointer', maxWidth: '100%', maxHeight: '100px', borderRadius: "8%" }}
                            onClick={() => setSelectedImg(`${S3_URL}/${document}`)}
                        />
                    )}
                </td>
                <td className='text-center'>{validUpto ? dateFormat(validUpto) : ""}</td>
                <td className='text-center'>{capitalizeWords(status) || "-"}</td>
                {status !== "pending" && <td className='text-center'>
                    <div className="dropdown">
                        <button
                            className=" border-0 bg-white"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-three-dots-vertical"
                                viewBox="0 0 16 16"
                            >
                                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                            </svg>
                        </button>
                        <ul
                            className="dropdown-menu"
                            style={{ zIndex: "1000" }}
                        >
                            {(status === "rejected" || status === "submitted") && <li
                                onClick={() => {

                                    setSelectDocument({ status: 'approved', keyName: keyName, validDate: validUpto, restaurantId: restaurantId })
                                    openModal()
                                }}
                            >
                                <a className="dropdown-item" href="#">
                                    Approve
                                </a>
                            </li>}

                            {(status === "approved" || status === "submitted") && <li
                                onClick={() => {
                                    setSelectDocument({ status: 'rejected', keyName: keyName, validDate: validUpto, restaurantId: restaurantId })
                                    openModal()
                                }}
                            >
                                <a className="dropdown-item" href="#">
                                    Reject
                                </a>
                            </li>}
                        </ul>
                    </div>
                </td>}
            </tr>
        );
    };


    const tabData = [
        {
            name: "Profile Details", tab: "driver", setTab: toggleTab, data: <div className="row card">
                <div className="row mt-3">
                    <div className="user-details">
                        <table className="table table-md table-hover card-table">
                            <tbody>
                                {
                                    profileData.map((ele, index) => (
                                        <tr key={index}>
                                            <td>{ele.name}:</td>
                                            <td>{ele.data}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        },
        { name: "Bank Details", tab: "bankDetails", setTab: toggleTab, data: <div className='p-3 card'><CommonTable headers={bankDetailsHeaderData} data={bankDetailsTableData} /></div> },
        {
            name: "Rate", tab: "rate", setTab: toggleTab, data:
                <div className="card">
                    <div className="card-header" style={{ backgroundColor: "var(--color-card-bg)" }}>
                        Rate
                    </div>
                    <div className="card-body" >
                        <div className='p-5'>
                            <form onSubmit={rateFormik.handleSubmit}>
                                <div className="form-group row row-cols-4">
                                    <div className='d-flex gap-3'>
                                        <label htmlFor='deliveryFee' className=" col-form-label">Reward Percentage fee</label>
                                        <div className="">
                                            <input type="text" className="form-control" id='rewardPercentage' name='rewardPercentage' placeholder="0" onChange={rateFormik.handleChange} onBlur={rateFormik.handleBlur} value={rateFormik.values.rewardPercentage} />
                                            {rateFormik.touched?.rewardPercentage && rateFormik.errors?.rewardPercentage ? (<div className='req-error'>{rateFormik?.errors?.rewardPercentage}</div>) : null}
                                        </div>
                                    </div>

                                    <div className='d-flex gap-3'>
                                        <label htmlFor='from' className=" col-form-label">From</label>
                                        <div className="">
                                            <DatePicker className='form-control' disabled dateFormat={"dd/MM/yyyy"} selected={new Date(rateFormik.values?.from)} onChange={(date) => { console.log(date) }} />
                                            {rateFormik.touched?.from && rateFormik.errors?.from ? (<div className='req-error'>{rateFormik?.errors?.from}</div>) : null}
                                        </div>
                                    </div>

                                    <div className='d-flex gap-3'>
                                        <label htmlFor='deliveryFee' className=" col-form-label">To</label>
                                        <div className="">
                                            <DatePicker className='form-control' name='to' dateFormat={"dd/MM/yyyy"} placeholderText='dd/MM/yyyy' selected={rateFormik.values?.to ? new Date(rateFormik.values?.to) : null} onChange={(date) => { rateFormik.setFieldValue("to", date)}} />
                                            {rateFormik.touched?.rewardPercentage && rateFormik.errors?.rewardPercentage ? (<div className='req-error'>{rateFormik?.errors?.rewardPercentage}</div>) : null}
                                        </div>
                                    </div>

                                    <div className='d-flex align-items-center gap-3'>
                                        <label htmlFor='rewardOnOff' className=" col-form-label">Reward (on/off)</label>
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" role="switch" checked={rateFormik?.values?.rewardOnOff} id="rewardOnOff" onChange={() => rateFormik?.setFieldValue("rewardOnOff", !Boolean(rateFormik?.values?.rewardOnOff))} />
                                            {rateFormik.touched?.rewardOnOff && rateFormik.errors?.rewardOnOff ? (<div className='req-error'>{rateFormik?.errors?.rewardOnOff}</div>) : null}
                                        </div>
                                    </div>
                                    

                                    <div className="d-flex align-items-end"  >
                                        <button type="submit" className="btn btn-sm btn-primary" disabled={rateLoader}>Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
        },
        {
            name: "Documents", tab: "document", setTab: toggleTab, data: <div className="row card ">
                <div className="mt-3 table-responsive  " style={{ overflowX: "inherit" }}>
                    <table className="table table-md table-hover card-table">
                        <thead>
                            <tr>
                                <th> <span className="list-sort text-muted">Type</span></th>
                                <th className='text-center'> <span className="list-sort text-muted">File</span></th>
                                <th className='text-center'> <span className="list-sort text-muted">Valid Upto</span></th>
                                <th className='text-center'> <span className="list-sort text-muted">Status</span></th>
                                <th className='text-center'> <span className="list-sort text-muted">Action</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <DocumentDisplay
                                title="Driving Licence"
                                document={salesAgentDetails?.drivingLicence}
                                setSelectedImg={setSelectedImg}
                                status={salesAgentDetails?.drivingLicenceApprovalStatus}
                                keyName={"drivingLicenceApprovalStatus"}
                                validUpto={salesAgentDetails?.drivingLicenceValidUpto}
                            />
                            <DocumentDisplay
                                title="National Id"
                                document={salesAgentDetails?.nationalId}
                                setSelectedImg={setSelectedImg}
                                status={salesAgentDetails?.nationalIdApprovalStatus}
                                keyName={"nationalIdApprovalStatus"}
                            />
                        </tbody>
                    </table>
                </div>
            </div>
        }
    ]

    return (
        <div className="main-content">
            <div className="container-fluid">

                {loader ? (
                    <div className="text-center">
                        <Spinwheel />
                    </div>
                ) : (
                    <>
                        <div className="row align-items-end">
                            <div className="col-auto">
                                <div className="avatar avatar-xxl header-avatar-top mt-3">
                                    <img src={profileDetails?.profileImage ? `${S3_URL}/${profileDetails?.profileImage}` : "https://img.freepik.com/premium-vector/man-avatar-profile-picture-vector-illustration_268834-538.jpg"} alt="profile" className="avatar-img rounded-circle border border-4 border-body" />
                                </div>
                            </div>
                            <div className="col mb-3 ms-n3 ms-md-n2">
                                <h1 className="header-title">
                                    {capitalizeWords(profileDetails.name)}
                                </h1>
                            </div>
                            <div className="col-12 col-md-auto mt-2 mt-md-0 mb-md-3">
                                {(profileDetails.approvalStatus === "pending") ? <><button type="button" className="btn btn-outline-primary me-2" data-bs-toggle="modal"
                                    data-bs-target={`#confirmationModal`}
                                    onClick={(e) => { setConfirmStatus(prevState => ({ ...prevState, status: "approved", userId: id })) }}>Approve</button>
                                    <button type="button" className="btn btn-outline-secondary" data-bs-toggle="modal"
                                        data-bs-target={`#confirmationModal`}
                                        onClick={(e) => { setConfirmStatus(prevState => ({ ...prevState, status: "rejected", userId: id })) }}>Reject</button></> :
                                    <div className={`badge fs-4  ${profileDetails.approvalStatus === "approved" ? "bg-primary-soft" : "bg-secondary-soft"}`}>{capitalizeWords(profileDetails.approvalStatus)}</div>
                                }
                            </div>
                        </div>

                        <div className='d-flex justify-content-between'>
                            <ul className="nav nav-tabs">
                                {
                                    tabData.map((ele, index) => (
                                        <li className="nav-item">
                                            <button
                                                className={`nav-link ${activeTab === ele.tab ? 'active' : ''}`}
                                                onClick={() => ele.setTab(ele.tab)}
                                            >
                                                {ele.name}
                                            </button>
                                        </li>
                                    ))
                                }
                            </ul>
                            <div>
                            </div>
                        </div>
                        {
                            tabData.map((ele) => (
                                activeTab === ele.tab ? ele.data : null
                            ))
                        }
                    </>
                )}

                <ConfirmationModal
                    handleConfirm={handleChangeStatus}
                    confirmStatus={confirmStatus}
                />
                <ImageViewModal imgUrl={selectedImg} modalId={"imageViewModal"} />
                <button id="confirmationModal2Btn" className='d-none' data-bs-toggle="modal" data-bs-target={`#${confirmationModalId2}`}></button>
                <ConfirmationModal modalId={confirmationModalId2} message={<> Do you want to continue?</>} handleConfirm={() => handleChangeDocumentStatus()} title={"Warning!"} confirmStatus={""} />
            </div>
        </div>

    );
};

export default SalesAgentDetails;
