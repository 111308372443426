import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { updateVehicleCategoryApi, getCategoryListApi, updateVehicleCategoryStatusApi, addVehicleCategoryApi, cityByCountryApi, countryListApi } from '../../../../utils/ApiEndpointFunctions'
import { capitalizeWords } from '../../../../utils/HelperFuncitons'

import DeletedModal from '../../../common/Modal/DeletedModal'
import { addCategoryInitialValue, addCategoryValidationSchema, categoryInitialValue, categoryValidationSchema } from '../../../common/Validation'
import CategoryAddModal from '../../../common/Modal/CategoryAddModal'
import { Link, useNavigate } from 'react-router-dom'
import CommonTable from '../../../common/Table/CommonTable'
import ReactSelect from 'react-select';

function CategoryTable({tableType}) {
  const [loader, setLoader] = useState(true);
  const [addLoader, setAddLoader] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedId, setSelectedId] = useState();
  const categoryModalId = "categoryFormModal"
  const addCategoryModalId = "addCategoryModalId"
  const deleteModalId = "categoryDeleteModal"
  const [countryOptions, setCountryOptions] = useState([])
  const [cityOptions, setCityOptions] = useState([])
  const navigate = useNavigate();



  const filterCites = async (countryId) => {
    try {
      const res = await cityByCountryApi("", countryId)
      if (res.status === 200) {
        let cityOption = res.data?.data?.map(ele => ({ label: ele.name, value: ele.id }))
        setCityOptions(cityOption);
      }

    } catch (error) {
      console.log(error)
    }
  };

  const filterCountry = async (inputValue) => {
    try {
      const res = await countryListApi(inputValue)
      if (res.status === 200) {
        let cityOption = res.data?.data?.map(ele => ({ label: ele.name, value: ele.id }))
        setCountryOptions(cityOption);
      }
    } catch (error) {
      console.log(error)
    }
  };

  const handleCityChange = (city) => {
    searchFormik.setFieldValue('cityId', city)
  }

  const handleCountryChange = (country) => {
    console.log(country, 'this is count')
    setCityOptions([])
    searchFormik.setFieldValue('countryId', country);
    searchFormik.setFieldValue('cityId', null);
    filterCites(country.value)
  }
  const getAllCategory = async () => {
    try {
      setLoader(true)
      const res = await getCategoryListApi(tableType, searchFormik.values?.countryId?.value, searchFormik.values?.cityId?.value)
      if (res.status === 200) {
        setTableData(res.data?.data)
      }
    } catch (error) {
      console.log(error)
    }
    setLoader(false)
  }

  const handleSubmit = async (values) => {
    try {
      setAddLoader(true)
      let payload = JSON.parse(JSON.stringify(values));
      payload.cityId = values.city.value
      payload.countryId = values.country.value
      const res = await updateVehicleCategoryApi(payload)
      if (res.status === 200) {
        toast.success(capitalizeWords(res.data?.message))
        getAllCategory();
        document.getElementById(categoryModalId).click();
        formik.resetForm();
      }
    } catch (error) {
      error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("Something went wrong");
      console.log(error)
    }
    setAddLoader(false)
  }

  const deleteCategory = async () => {
    setAddLoader(true)
    try {
      const res = await updateVehicleCategoryStatusApi({ id: selectedId, status: false })
      if (res.status === 200) {
        document.getElementById(deleteModalId).click();
        toast.success(capitalizeWords(res.data?.message))
        getAllCategory();
      }
    } catch (error) {
      console.log(error)
      error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("Something went wrong");
    }
    setAddLoader(false)
  }

  const updatingValueHandler = (value) => {
    if(value?.cityId && value?.countryId) {
      value.country = {
        label: value?.countryName, value: value?.countryId
      }
      value.city = {
        label: value?.cityName, value: value?.cityId
      }

    }else{
      value.countryId = null
      value.cityId = null
    }
    formik.setValues(value)
  };

  const searchFormik = useFormik({
    initialValues: {
      countryId: null,
      cityId: null,
    },    
  })


  const formik = useFormik({
    initialValues: categoryInitialValue,
    onSubmit: handleSubmit,
    validationSchema: categoryValidationSchema
  })

  const handleAddCategory = async (values) => {
    try {
      setAddLoader(true)
      let payload = JSON.parse(JSON.stringify(values));
      const res = await addVehicleCategoryApi(payload)
      if (res.status === 200) {
        toast.success(capitalizeWords(res.data?.message))
        getAllCategory();
        document.getElementById(addCategoryModalId).click();
        addCategoryFormik.resetForm();
      }
    } catch (error) {
      error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("Something went wrong");
      console.log(error)
    }
    setAddLoader(false)
  }

  const addCategoryFormik = useFormik({
    initialValues: addCategoryInitialValue,
    onSubmit: handleAddCategory,
    validationSchema: addCategoryValidationSchema
  })


  useEffect(() => {
    getAllCategory();
    
  }, [searchFormik.values])

  useEffect(() => {
    filterCountry()
  }, [])

  const headerData = tableType === "yiinoRide" ? [
    { name: "S.No.", className: "col", key: "index" },
    { name: "Place", className: "col", key: "place" },
    { name: "Class", className: "col", key: "category" },
    { name: "Base rate", className: "col", key: "baseRate" },
    { name: "Per KM", className: "col", key: "perMiles" },
    { name: "Per Minute", className: "col", key: "perMinute" },
    // { name: "Surge", className:"col", key:"surcharge" },
    { name: "Ride Booking", className: "col", key: "rideBookingFee" },
    { name: "Wait fee", className: "col", key: "waitFee" },
    { name: "Platform Fee(%)", className: "col", key: "platformFee" },
    { name: "Cancellation", className: "col", key: "cancellationFee" },
    { name: "other stop (min)", className: "col", key: "otherStopFee" },
    { name: "City tax(%)", className: "col", key: "cityTax" },
    { name: "Action", className: "col", key: "action" },
  ] : [
    { name: "S.No.", className: "col", key: "index" },
    { name: "Place", className: "col", key: "place" },
    { name: "Category", className: "col", key: "category" },
    { name: "Flat rate", className: "col", key: "baseRate" },
    { name: "Per Minute", className: "col", key: "perMinute" },
    // { name: "Surge", className:"col", key:"surcharge" },
    { name: "Pickup Fee", className: "col", key: "rideBookingFee" },
    { name: "Cancellation", className: "col", key: "cancellationFee" },
    { name: "Platform Fee(%)", className: "col", key: "platformFee" },
    { name: "City tax(%)", className: "col", key: "cityTax" },
    { name: "Action", className: "col", key: "action" },
]

  const categoryTableData = tableData?.map((ele, index) => ({
    index: index + 1,
    place: (
      <Link className='text-primary' to={tableType === "yiinoPackage" ? `/partners/package/category?op=update&id=${ele.id}` : `/partners/driver/category-update?id=${ele.id}`}>
        {/* <div>{ele?.countryName}</div>
          <hr className='p-0 mt-1 mb-1 text-secondary' />
          <div>{ele?.cityName}</div> */}
        {ele?.cityName},{" "}{ele?.countryName}
      </Link>
    ),
    category: ele?.category ? capitalizeWords(ele?.category) : "-",
    baseRate: ele?.baseRate ?? "-",
    perMiles: ele?.perMiles ?? "-",
    perMinute: ele?.perMinute ?? "-",
    // surcharge: ele?.surcharge ?? "-",
    rideBookingFee: ele?.rideBookingFee ?? "-",
    waitFee: ele?.waitFee ?? "-",
    platformFee: ele?.platformFee ?? "-",
    cancellationFee: ele?.cancellationFee ?? "-",
    otherStopFee: ele?.otherStopFee ?? "-",
    cityTax: ele?.cityTax ?? "-",
    action: (
      <>
        <div className="dropdown">
          <button
            className=" border-0 bg-white"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-three-dots-vertical"
              viewBox="0 0 16 16"
            >
              <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
            </svg>
          </button>
          <ul
            className="dropdown-menu"
            style={{ zIndex: "1000" }}
          >
            <li className="dropdown-item">
              <div
                onClick={() => { navigate(tableType === "yiinoPackage" ? `/partners/package/category?op=update&id=${ele.id}` : `/partners/driver/category-update?id=${ele.id}`) }}
                style={{ cursor: "pointer" }}
              >
                View / Edit
              </div>
            </li>

            <li className="dropdown-item" >
              <div
                data-bs-toggle="modal"
                data-bs-target={`#${deleteModalId}`}
                onClick={() => { setSelectedId(ele?.id) }}
                style={{ cursor: "pointer" }}
              >
                Delete
              </div>
            </li>
          </ul>
        </div>
      </>
    )
  }))

  return (
    <><div className="d-flex justify-content-end flex-wrap align-items-center mt-3 mt-lg-0">
      <div style={{ display: "-webkit-inline-box" }}>
        <div className="position-relative my-3">

          <div className='d-flex gap-4'>
            <div className="d-flex align-items-center mb-3 gap-2">
              <label htmlFor="city" className="form-label">Country</label>
              <ReactSelect className='reactSelectMinWidth'  options={countryOptions} value={searchFormik?.values?.countryId} onChange={handleCountryChange} onBlur={searchFormik?.handleBlur} noOptionsMessage={() => 'No Countries Available'} />
              {searchFormik?.touched?.countryId && searchFormik?.errors?.countryId ? (<div className='req-error'>{searchFormik?.errors?.countryId}</div>) : null}
            </div>

            <div className="d-flex align-items-center mb-3 gap-2">
              <label htmlFor="city" className="form-label">City</label>
              <ReactSelect className='reactSelectMinWidth' options={cityOptions} isDisabled={!searchFormik?.values?.countryId} value={searchFormik?.values?.cityId} onChange={handleCityChange} onBlur={searchFormik?.handleBlur} noOptionsMessage={() => 'No Cities Available'} />
              {searchFormik?.touched?.cityId && searchFormik?.errors?.cityId ? (<div className='req-error'>{searchFormik?.errors?.cityId}</div>) : null}
            </div>

            <div>
              <div>
                <button
                  className="btn btn-primary"              
                  onClick={() => { searchFormik.resetForm(); }}
                >
                  Reset filter
                </button>
              </div>
            </div>

          <div>
              <button
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target={`#${addCategoryModalId}`}
                onClick={() => { addCategoryFormik.resetForm(); }}
              >
                Add
              </button>
          </div>

          </div>
         
        </div>
      </div>
    </div>

      <CommonTable headers={headerData} data={categoryTableData} loading={loader} />

      <CategoryAddModal modalId={addCategoryModalId} addLoader={addLoader} formik={addCategoryFormik} tableType={tableType}/>

      <DeletedModal
        modalId={deleteModalId}
        message={"this Category"}
        deletedHandler={deleteCategory}
        loader={addLoader}
      />
    </>
  )
}

export default CategoryTable