import React from 'react'
import { capitalizeWords, dateTimeFormat } from '../../../utils/HelperFuncitons'

function RidesDetailModal({ rideDetail, modalId, activeTab, rideStatus, hireRequestStatus, restaurantOrderStatus }) {

  const tableData = (activeTab === "rides" || activeTab === "packageDelivery") ? [
    { title: "Id", value: rideDetail?.id },
    { title: "Customer", value: `${rideDetail?.user?.firstName ? capitalizeWords(rideDetail?.user?.firstName) : ""} ${rideDetail?.user?.lastName ? capitalizeWords(rideDetail?.user?.lastName) : ""}` },
    ...(activeTab === "packageDelivery" ? [{ title: "Receiver", value: `${rideDetail?.receiverName ? capitalizeWords(rideDetail?.receiverName) : ""}` }] : []),
    { title: "Driver", value: rideDetail?.bookingDetail?.partnerUser?.partnerUserDetails?.[0]?.name ? rideDetail?.bookingDetail?.partnerUser?.partnerUserDetails?.[0]?.name : "-" },
    { title: "PickUp", value: rideDetail?.pickup ? rideDetail?.pickup : "-" },
    { title: "DropOff", value: rideDetail?.dropOff ? rideDetail?.dropOff : "-" },
    { title: "Fare", value: rideDetail?.charges ? rideDetail?.charges : "-" },
    { title: "Payment Mode", value: rideDetail?.paymentMode ? rideDetail?.paymentMode : "-" },
    { title: "Pickup Time", value: rideDetail?.bookingDetail?.time ? dateTimeFormat(Number(rideDetail?.bookingDetail?.time)) : "-" },
    { title: "DropOff Time", value: rideDetail?.bookingDetail?.dropOffTime && rideDetail?.bookingDetail?.status === "completed" ? dateTimeFormat(Number(rideDetail?.bookingDetail?.dropOffTime)) : "-" },
    { title: "Total Time", value: rideDetail?.duration ? rideDetail?.duration : "-" },
    { title: "Total KM", value: rideDetail?.distance ? rideDetail?.distance : "-" },
    { title: "Status", value: rideStatus(rideDetail?.isCancelled ? "cancelled" : rideDetail?.bookingDetail?.status, rideDetail?.bookingDetail?.role) },
  ]
    : activeTab === "hire" ? [
      { title: "Id", value: rideDetail?.id },
      { title: "Customer", value: `${rideDetail?.user?.firstName ? capitalizeWords(rideDetail?.user?.firstName) : ""} ${rideDetail?.user?.lastName ? capitalizeWords(rideDetail?.user?.lastName) : ""}` },
      { title: "Vendor", value: rideDetail?.bookingDetailsRental?.partnerUser?.partnerUserDetails?.[0]?.name ? rideDetail?.bookingDetailsRental?.partnerUser?.partnerUserDetails?.[0]?.name : "-" },
      { title: "PickUp", value: rideDetail?.pickup ? rideDetail?.pickup : "-" },
      { title: "DropOff", value: rideDetail?.dropOff ? rideDetail?.dropOff : "-" },
      { title: "Fare", value: rideDetail?.charges ? rideDetail?.charges : "-" },
      { title: "Payment Mode", value: rideDetail?.paymentMode ? rideDetail?.paymentMode : "-" },
      { title: "Pickup Time", value: rideDetail?.pickUpTime ? dateTimeFormat(rideDetail?.pickUpTime) : "-" },
      { title: "DropOff Time", value: rideDetail?.dropOffTime ? dateTimeFormat(rideDetail?.dropOffTime) : "-" },
      { title: "Total Time", value: rideDetail?.duration ? rideDetail?.duration : "-" },
      { title: "Status", value: hireRequestStatus(rideDetail?.isCancelled ? "cancelled" : rideDetail?.bookingDetailsRental?.status, (rideDetail?.bookingDetailsRental?.role ?? "user")) },
    ]
      : [
        { title: "Id", value: rideDetail?.id },
        { title: "Customer", value: `${rideDetail?.user?.firstName ? capitalizeWords(rideDetail?.user?.firstName) : ""} ${rideDetail?.user?.lastName ? capitalizeWords(rideDetail?.user?.lastName) : ""}` },
        { title: "Restaurant", value: rideDetail?.restaurant?.name ? capitalizeWords(rideDetail?.restaurant?.name) : "-" },
        {
          title: "Food Items", value: (
            rideDetail?.foodOrderItems?.map(el => <>
              <tr>{el?.menuItem?.name}</tr>
            </>)
          )
        },
        { title: "Restaurant Address", value: rideDetail?.restaurant?.address ? capitalizeWords(rideDetail?.restaurant?.address) : "-" },
        { title: "Delivery Partner", value: rideDetail?.driverDetails?.name ? capitalizeWords(rideDetail?.driverDetails?.name) : "-" },
        { title: "Ride Booking Id", value: rideDetail?.driverDetails?.rideBookingId || "-" },
        { title: "Delivery Address", value: rideDetail?.deliveryAddress?.address ?? "-" },
        { title: "Fare", value: rideDetail?.totalAmount ? rideDetail?.totalAmount : "-" },
        { title: "Driver charge", value: rideDetail?.driverDetails?.driverCharges },
        { title: "Payment Mode", value: rideDetail?.paymentMode ? rideDetail?.paymentMode : "-" },
        { title: "Date", value: rideDetail?.createdAt ? dateTimeFormat(rideDetail?.createdAt) : "-" },
        { title: "Status", value: restaurantOrderStatus(rideDetail?.status, rideDetail?.cancelledBy) },
      ]

  return (
    <div className="modal fade" id={modalId} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">

          <div className="modal-body ">
            <div className=' d-flex justify-content-between'>
              <h2 >
                Ride Details
              </h2>
              <div className=' border-0'>

                <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
            </div>
            <table className='table'>
              <tbody>
                {tableData.map((el, index) => <tr key={index}>
                  <td>{el.title}</td>
                  <td>{el.value}</td>
                </tr>)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RidesDetailModal