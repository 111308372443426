import React, { useEffect, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Dashboard from '../components/authorized/dashboard/Dashboard'
import Login from '../components/unauthorized/Login'
import Users from '../components/authorized/partners/Partners'
import Sidebar from '../components/Layout/Sidebar'
import Header from '../components/common/Header'
import { getAdminDetailsApi } from '../utils/ApiEndpointFunctions'
import { useAdminContext } from '../context/adminContext';
import { removeToken } from '../utils/HelperFuncitons'
import DriverDetails from '../components/authorized/drivers/DriverDetails'
import RentalVendorDetails from '../components/authorized/rentalVendor/RentalVendorDetails'
import Customers from '../components/authorized/Customers/Customers'
import Rides from '../components/authorized/rides/Rides'
import CustomerDetails from '../components/authorized/Customers/CustomerDetails'
import GlobalSetting from '../components/authorized/global/GlobalSetting'
import AddOrEditCategory from '../components/authorized/partners/driver/category/AddOrEditCategory'
import Spinwheel from '../components/common/Spinwheel'
import Administrator from '../components/authorized/superAdmin/administrator/Administrators'
import AddOrEditPackageCategory from '../components/authorized/partners/package/AddOrEditPackageCategory'
import AddOrEditRentalCategory from '../components/authorized/partners/rentals/AddOrEditRentalCategory'
import RestaurentDetails from '../components/authorized/restaurent/RestaurentDetails'
import SalesAgentDetails from '../components/authorized/salesAgent/salesAgentDetails'
import WithdrawalRequest from '../components/authorized/withdrawalRequest/WithdrawalRequest'

import toast from 'react-hot-toast'
import Referrals from '../components/authorized/refferals/Referrals'

// import Spinwheel from '../components/common/Spinwheel'

function PrivateRoute({ children }) {
  const auth = localStorage.getItem("token");
  return auth ? children : <Navigate to="/" />;
}

function PublicRoute({ children }) {
  const auth = localStorage.getItem("token");
  return !auth ? children : <Navigate to="/dashboard" />;
}

export default function Mainroute() {

  const [loader, setLoader] = useState(true)
  const token = localStorage.getItem("token")
  const { setAdminDetail, adminDetail } = useAdminContext();

  const getAdminDetails = async () => {
    try{
      const { data } = await getAdminDetailsApi();
      if (data && data.data) {
        setAdminDetail(data.data)
      } else {
        removeToken();
      }
    }catch(err){
      // removeToken();
      toast.error(err?.response?.data?.message || "Something Went Wrong")
    }

    setLoader(false)
  }

  useEffect(() => {
    if (token) {
      getAdminDetails()
    }else{
      setLoader(false)
    }
  }, [token])

  if(loader){
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <div className='text-center'>
      <Spinwheel />
    </div>
  </div>
  }

  return (

    <BrowserRouter>

      <Sidebar />

      <Header />

      <Routes>
        <Route exact path="/partners" element={<PrivateRoute><Users /></PrivateRoute>} > </Route>
        <Route path="/partners/driver/:id" element={<PrivateRoute><DriverDetails /></PrivateRoute>} />
        <Route path="/partners/rental/:id" element={<PrivateRoute><RentalVendorDetails /></PrivateRoute>} />
        <Route path="/partners/restaurent/:id" element={<PrivateRoute><RestaurentDetails /></PrivateRoute>} />
        <Route path="/partners/salesAgent/:id" element={<PrivateRoute><SalesAgentDetails /></PrivateRoute>} />
        <Route exact path="/customers" element={<PrivateRoute><Customers /></PrivateRoute>} />
        <Route exact path="/customers/:id" element={<PrivateRoute><CustomerDetails /></PrivateRoute>} />
        <Route exact path="/withdrawal-request/:id?" element={<PrivateRoute><WithdrawalRequest /></PrivateRoute>} />
        <Route exact path="/rides" element={<PrivateRoute><Rides /></PrivateRoute>} />
        <Route exact path="/partners/driver/defaultCategory-add" element={<PrivateRoute><AddOrEditCategory /></PrivateRoute>} />
        <Route exact path="/partners/driver/category-update" element={<PrivateRoute><AddOrEditCategory /></PrivateRoute>} />
        <Route exact path="/partners/driver/defaultCategory-update" element={<PrivateRoute><AddOrEditCategory /></PrivateRoute>} />
        <Route exact path="/partners/package/defaultCategory" element={<PrivateRoute><AddOrEditPackageCategory /></PrivateRoute>} />
        <Route exact path="/partners/package/category" element={<PrivateRoute><AddOrEditPackageCategory /></PrivateRoute>} />
        <Route exact path="/partners/rentals/defaultCategory" element={<PrivateRoute><AddOrEditRentalCategory /></PrivateRoute>} />
        {/* <Route exact path="/partners/rentals/category" element={<PrivateRoute><AddOrEditRentalCategory /></PrivateRoute>} /> */}
        <Route path="*" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
        <Route exact path="/payment-status" element={<PrivateRoute><div></div></PrivateRoute>} />
        <Route exact path="/referrals" element={<PrivateRoute><Referrals /></PrivateRoute>} />

        {adminDetail?.role === "superAdmin" && <>
        <Route exact path="/global-settings" element={<PrivateRoute><GlobalSetting/></PrivateRoute>} />
        <Route exact path="/administrators" element={<PrivateRoute><Administrator/></PrivateRoute>} />
        </>}
        
        <Route exact path="/login" element={<PublicRoute><Login /></PublicRoute>} />
        <Route path="/*" element={<PublicRoute><Login /></PublicRoute>} />
        <Route exact path="/" element={<PublicRoute><Login /></PublicRoute>} />


      </Routes>
    </BrowserRouter>

  )
}

